import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { sum } from "lodash";
import logo from "../../assets/iepd_logo_2018.png";

const Wrapper = styled.div`
  &:after {
    content: "";
    position: absolute;
    /* scroll-snap-align: start; */
  }

  & > .bio-wrap {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease;
    background: #fff;
    white-space: pre-wrap;
    font-size: 14px;
    max-width: min(450px, 85vw);
    width: 100vw;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 0 25px rgb(0 0 0 / 21%);
    z-index: 10000;
    max-height: 90vh;
    height: min(600px, 90vh);

    &.show {
      pointer-events: all;
      user-select: all;
      opacity: 1;
    }
  }

  & .bio-tooltip {
    position: fixed;
    background: #fff;
    white-space: pre-wrap;
    font-size: 14px;
    user-select: none;
    padding: 40px;
    pointer-events: none;
    box-shadow: 0 0 25px rgb(0 0 0 / 21%);
    z-index: 10000;
    white-space: nowrap;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  a:hover {
    color: #444;
  }

  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-around;
  position: relative;
  padding-bottom: 100px;

  & h1 {
    width: min(1500px, 90vw);
    font-size: 40px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 641px) {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    @media screen and (max-width: 640px) {
      margin-bottom: 20px;
      width: 270px;
      max-width: 90vw;
    }
  }

  @media screen and (max-width: 640px) {
    position: static;

    & + div {
      display: none;
    }
  }

  & > div {
    max-width: min(1500px, 90vw);
    width: min(1500px, 90vw);
    margin: 0 auto;
  }

  & > .team {
    min-height: calc(100vh - 90px);
    place-content: center;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2vw;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 100px 0;

    & .name {
      margin: 10px 0 5px;
      font-weight: 700;
    }

    & .spacer {
      display: none;
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 640px) {
      grid-template-columns: repeat(2, 1fr);

      & .spacer {
        display: block;
      }

      & h1 {
        width: fit-content;
      }

      & .member {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 100px;
        }
      }
    }

    & .member {
      max-width: 270px;
      white-space: nowrap;

      & .image {
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 0 2px #fff;
        transition: box-shadow 0.2s ease;

        & .bio-tooltip {
          display: none;
        }

        &.bio:hover {
          & .bio-tooltip {
            display: block;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);

      & .spacer {
        display: none;
      }

      & .member {
        max-width: 100%;
        white-space: nowrap;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    & .bio-wrap {
      display: none !important;
    }
  }

  @media screen and (max-width: 1023px) {
    & .bio-tooltip {
      display: none !important;
    }

    & .member {
      & .bio:hover {
        cursor: pointer;
      }
    }
  }
`;

const Team = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const [bio, setBio] = React.useState("");
  const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });

  const handleKeyDown = (e) => {
    console.log(e.key);
    if (e.key === "Escape") {
      setVisible(false);
    }
  };

  const handleMouseMove = (e) => {
    setMousePos({
      x: e.x + 20,
      y: e.y + 10,
    });
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <Wrapper
        onClick={(e) => {
          if (!e.target.closest(".bio-wrap")) setVisible(false);
        }}
      >
        <div className={`bio-wrap ${visible && "show"}`}>
          <SimpleBar
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "100%",
              padding: "40px",
              boxSizing: "border-box",
            }}
          >
            {bio}
          </SimpleBar>
        </div>

        <div className="team">
          <h1>{t("header.atelier")}</h1>
          <div className="spacer"></div>
          {data.allMarkdownRemark.edges[0].node.frontmatter.ludia.map(
            ({ meno, pozicia, tel, email, obrazok, bio }, index) => (
              <div
                key={index}
                className="member"
                onClickCapture={(e) => {
                  if (bio) {
                    e.stopPropagation();
                    setVisible((p) => !p);
                    setBio(
                      <>
                        <div className="name" style={{ fontWeight: "bold" }}>
                          {meno}
                        </div>
                        <div>{pozicia[i18n.language]}</div>
                        <br />
                        {bio[i18n.language].split(`\n`).map((row, i) => (
                          <div key={i} style={{ marginBottom: 10 }}>
                            {row}
                          </div>
                        ))}
                      </>
                    );
                  }
                }}
              >
                <div className={`image ${bio ? "bio" : ""}`}>
                  <GatsbyImage image={getImage(obrazok)} />
                  {bio && (
                    <div
                      className="bio-tooltip"
                      style={{ left: mousePos.x, top: mousePos.y }}
                    >
                      {bio[i18n.language].split(`\n`).map((row, i) => (
                        <div key={i} style={{ marginBottom: 10 }}>
                          {row}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="name">{meno}</div>
                <div>{pozicia[i18n.language]}</div>
                <div>{tel}</div>
                <div>{email}</div>
              </div>
            )
          )}
        </div>
        <div>
          <h1
            style={{ position: "static", transform: "none", marginBottom: 50 }}
          >
            {t("header.spolupracovali")
              .split("/")
              .map((v, i) => (
                <span key={i}>
                  {v}
                  {i === 0 ? "/" : ""}
                </span>
              ))}
          </h1>
          <ul>
            {data.allMarkdownRemark.edges[0].node.frontmatter.spolupracovali.map(
              ({ meno, link }) => (
                <li>
                  {typeof link === "string" && link.length > 2 ? (
                    <a href={link}>{meno}</a>
                  ) : (
                    meno
                  )}
                </li>
              )
            )}
          </ul>
          <div style={{ margin: "200px auto 50px", textAlign: "center" }}>
            <h3 style={{ fontSize: 32, marginBottom: 40 }}>
              Sme členmi Inštitútu pre pasívne domy (IEPD)
            </h3>
            <img src={logo} alt="" style={{ width: "140px" }} />
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Team;
