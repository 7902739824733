import React from "react";
import Layout from "../components/Layout";
import Team from "../components/sections/Team";
import { graphql } from "gatsby";
// import { useTranslation } from "gatsby-plugin-react-i18next";

const Atelier = ({ data, ...meta }) => {
  // const { t } = useTranslation();
  return (
    <Layout meta={meta}>
      <Team data={data} />
    </Layout>
  );
};

export default Atelier;

export const pageQuery = graphql`
  query AtelierPage($language: String!) {
    allMarkdownRemark(filter: { fields: { slug: { eq: "/atelier/" } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            spolupracovali {
              meno
              link
            }
            ludia {
              meno
              pozicia {
                en
                sk
              }
              tel
              email
              bio {
                en
                sk
              }
              obrazok {
                base
                url: publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
